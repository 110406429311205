import background from './assets/under-construction.jpg';

function App() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
        <p
          style={{
            color: "white",
            fontSize: 50,
            fontFamily: "Barlow Condensed, sans-serif",
          }}
        >DANIELA TRINDADE</p>
    </div>
  );
}

export default App;
